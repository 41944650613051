export const fuelReportDivisions = ['Смена № 1', 'Смена № 2', 'Смена № 3', 'Смена № 4'];
export const fuelReportTableHeaderSells = [
  'Пробег',
  'Расход топлива',
  'Пробег',
  'Расход топлива',
  'Пробег',
  'Расход топлива',
  'Пробег',
  'Расход топлива',
  'Общий пробег',
  'Общий расход топлива',
];
